;
(function ($) {
    $(document).ready(function () {
        $('.header-wrapper')[$(window).scrollTop() > 180 ? 'addClass' : 'removeClass']('sticky');
        //END ready
    });

    // Window Resize
    $(window).resize(function () {
        // End window Resize
    });

    // Window Scroll
    $(window).on('scroll', function() {
        $('.header-wrapper')[$(window).scrollTop() > 180 ? 'addClass' : 'removeClass']('sticky');
    });
})(jQuery);